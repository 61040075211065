<template >
    <div class="row" v-if="data.prix_japan || data.link_pmda"> 
        <div class="col-12 col-lg-12 wrapper-box-fiche"> 
            <div class="box-white-fiche">
                <table class="comparateur2 text-center" v-if="data.link_pmda.length">
                    <thead>
                        <tr>
                            <th class="gray">SAKIGAKE designation</th>
                            <th class="gray">Priority review</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td>{{data.link_pmda[0].priority_review ? data.link_pmda[0].priority_review : '-'}}</td>
                        <td>{{data.link_pmda[0].sakigake_designation ? data.link_pmda[0].sakigake_designation : '-'}}</td>
                    </tbody>
                </table>

                <table class="comparateur2 text-center" v-if="data.prix_japan.price_json.length">
                    <thead>
                        <tr>
                            <th class="gray">Public Price (VAT included) (Yen)</th>
                            <th class="gray">Public Price (VAT excluded) (Yen)</th>
                            <th class="gray">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr v-for="(value_price, key) in data.prix_japan.price_json" :key="'price_'+key">
                                <td>{{value_price.price_included}}</td>
                                <td>{{value_price.price_excluded}}</td>
                                <td>{{value_price.price_date}}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>   
</template>


<script>
export default {
  name: "prix_japan",
  components: {},
  methods: {},
  mounted() {
    if (this.$i18n.locale == "de") {
      this.lang = "de";
    }
  },
  updated() {},
  computed: {
      data() {
            return this.$store.getters['detail/data']
        }
  },
  unmounted() {},
};
</script>